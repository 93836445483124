import React, { Component } from 'react'

class Accordion extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeIndex: -1
    }
  }

  setActiveIndex = (index) => {
    if (this.state.activeIndex === index)
      this.setState({ activeIndex: -1 })
    else
      this.setState({ activeIndex: index })
  }

  render() {
    let { activeIndex } = this.state
    let accordionContent = React.Children.map(this.props.children, (child, index) => {
      return (
        <div className={`accordion__section ${activeIndex === index ? 'is-active' : ''}`}> 
          <h2
            className="accordion__section-heading"
            onClick={() => this.setActiveIndex(index)}>
            <span>{child.props.label}</span>
            {activeIndex === index ? 
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="2" viewBox="0 0 20 2">
                <g transform="translate(-1240.5 -162.5)">
                  <line y2="20" transform="translate(1260.5 163.5) rotate(90)" fill="none" stroke="#101010" strokeWidth="2"/>
                </g>
              </svg> : 
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <g transform="translate(-1240.5 -153.5)">
                  <line y2="20" transform="translate(1250.5 153.5)" fill="none" stroke="#101010" strokeWidth="2"/>
                  <line y2="20" transform="translate(1260.5 163.5) rotate(90)" fill="none" stroke="#101010" strokeWidth="2"/>
                </g>
              </svg>
            }
          </h2>
          <div className="accordion__section-content">
            {this.props.children[index]}
          </div>
        </div>
      )
    })
    return (
      <>
        <div className="accordion">
          {accordionContent}
        </div>
      </>
    )
  }
}

export default Accordion