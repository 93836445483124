import { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import Panel from './panel'
import Accordion from './accordion'
import './App.scss'

const studio = {
  content: [
  <article key="studio" className="panel--studio">
    <p>Assemblage Studio partners with individuals, businesses and organizations to authentically articulate and realize their commitments to responsible practices through design and business development.</p>
  </article>],
  color: '#E57845'
}
const contact = {
  content: [
    <div key="contact" className="panel--contact">
      <div>
        <p>Stay in touch for updates and ways to get involved.</p>
        <p><a href="mailto:home@assemblageworld.com" title="Email">home@assemblageworld.com</a><br/> 
        <a href="https://instagram.com/assemblageworld" title="Instagram" target="_blank" rel="noreferrer">@assemblageworld</a></p>
      </div>
      <span>&copy; Assemblage World Ltd., 2021</span>
    </div>
  ],
  color: '#0079A3'
}
const about = {
  content: [
    <div key="about" className="panel--about">
      <div>
        <p>Assemblage World is an endeavor to transition our world to a higher environmental and social consciousness through intentional narrative, responsible commerce and brand architecture.</p>
      </div>
      <Accordion>
        <article label="Founding Team">
          <div>
            <h3>Marisa Ma</h3>
            <p>Marisa strives for a paradigm shift of responsible practices through renewed standards of what it is to be a conscience-led brand and the operation of them.</p>
            <p>Her professional experience began in entertainment  PR and communications in New York City and continued in journalism for bi-lingual publications in Italy. During this period, she delved into business development for fashion and lifestyle projects. Fifteen years later, she co-founded Atelier &amp; Repairs, a fully vertical business model based on principles of the circular economy. Marisa has contributed to various sustainability focused publications including The Ellen MacArthur Foundation's Make Fashion Circular case studies.</p>
            <p>Born in Hong Kong, Marisa spent her formative years in California before studying at New York University. She currently lives in Los Angeles with her husband and son.</p>
          </div>
          <div>
            <h3>Kate Montana</h3>
            <p>Kate is driven by a mission to bring positive, holistic, and sustainable change to our production and consumption habits through inspiring open information and knowledge exchange.</p>
            <p>With experience in hospitality and lifestyle copywriting and retail marketing and activations, Kate joined the founding team of Atelier &amp; Repairs in 2015 focusing on the brand narrative while driving the company's commercial reach to key global retailers and partners.</p>
            <p>Kate was born in California and earned a BA in Global Communications with a concentration in Writing for the Media from the American University of Rome, followed by projects in Spain and New York City. She currently lives in Los Angeles.</p>
          </div>
        </article>
        <article label="Advisory Team">
          <div>
            <h3>Jessica Allen</h3>
            <p>Jessica brings over two decades of experience and a proven ability in fashion public relations of capturing and telling a brand's larger story and effectively reaching readers and consumers in a directed, meaningful way.</p>
            <p>Her strategic acumen is measured by the consistent results of her high-value interaction and personal relationships with top-tier editors, volumes of specialized coverage and the expansion of consumer awareness and overall business growth by her clients.</p>
            <p>Jessica lives and runs all over New York City and is always training for the next marathon. As a lifelong vegetarian and environmental steward, her main focus is promoting and supporting brands that are actively engaged in rebuilding the apparel business into an environmentally responsible and sustainable industry.</p>
          </div>
          <div>
            <h3>Dasha Kuni</h3>
            <p>Dasha has spent over 20 years working in the fashion industry with clients and brands such as Chloe, Estee Lauder, Ralph Lauren, Tory Burch and The Row. Her experience includes window design, International advertising campaigns, visual merchandising, couture archiving and personal and editorial styling. Her work and international campaigns continue to be featured in Vogue, Harper's Bazaar and Elle magazines.</p>
            <p>Dasha's commitment to our environment and community started at a young age. She holds a Bachelors of Science degree in Biology, and is a published co-author of several scientific papers in the field of organic chemistry. Dasha lives with her family in New York City where she makes clean living and positive social impact choices a priority.</p>
          </div>
          <div>
            <h3>Maurizio Donadi</h3>
            <p>Maurizio's move towards responsible environmental practices began with co-founding the circular economy principled Atelier &amp; Repairs in 2015, which continues its mission of redesigning leftover apparel in Los Angeles.</p>
            <p>His most recent endeavor, Transnomadica, is aimed at redefining the relationship individuals and brands have with leftover goods, through creatively highlighting their cultural significance, innovation and beauty.  Founded upon his personal archive curated and collected over decades, Transnomadica works with design, merchandising and retail teams to realize circularity, authentically.</p>
            <p>Maurizio brings more than 40 years experience in the fashion industry having held executive positions at Giorgio Armani and Armani Exchange, Ralph Lauren, Levi Strauss &amp; Company, Diesel and Benetton. As an innovative brand strategist and product creative, Maurizio has driven creative and commercial initiatives that are both globally scaled and locally relevant in both mass and niche markets.</p>
          </div>
        </article>
      </Accordion>
    </div>
  ],
  color: '#F7CA46'
}

const hours = moment().tz("America/Los_Angeles").format("HH")
const mins = moment().tz("America/Los_Angeles").format("mm")

function App() {
  const [content, setContent] = useState('')
  const [panelOpen, setPanelOpen] = useState(false)
  const [height, setHeight] = useState(window.innerHeight)

  function closePanel() {
    setPanelOpen(false)
  }

  function updateWindowDimensions() {
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)
  }, [])

  return (
    <>
      <div
        style={{height: height + 'px'}} 
        className="grid">
        <header className="header">
          <div className="logo">
            <svg xmlns="http://www.w3.org/2000/svg" width="330.878" height="25.77" viewBox="0 0 330.878 25.77">
              <g transform="translate(-701.678 -887.433)">
                <path d="M708.952,906.931l-1.94,6.027h-5.334l8.555-24.663h6.858l8.556,24.663h-5.335l-1.9-6.027Zm7.552-5.992c-1-3.187-1.975-6.443-2.806-9.63h-.069c-.832,3.187-1.767,6.443-2.772,9.63l-.52,1.627h6.686Z" transform="translate(0 -0.309)"/>
                <path d="M765.955,895.537h-5.161c0-2.563-1.489-4.052-4.3-4.052-2.251,0-3.775,1.108-3.775,2.7,0,1.906,1.489,2.529,5.369,3.5,4.641,1.143,8.659,2.355,8.659,7.723,0,4.677-3.879,7.794-9.629,7.794-6.131,0-9.976-3.048-9.976-8.659h5.265c0,2.979,1.629,4.537,4.781,4.537,2.6,0,4.26-1.213,4.26-3.152,0-2.182-1.593-2.6-5.092-3.5-4.641-1.178-8.867-2.285-8.867-7.688,0-4.331,3.706-7.309,9.109-7.309C762.768,887.433,765.955,890.238,765.955,895.537Z" transform="translate(-16.304)"/>
                <path d="M807.265,895.537H802.1c0-2.563-1.489-4.052-4.3-4.052-2.251,0-3.775,1.108-3.775,2.7,0,1.906,1.49,2.529,5.369,3.5,4.641,1.143,8.659,2.355,8.659,7.723,0,4.677-3.879,7.794-9.629,7.794-6.131,0-9.976-3.048-9.976-8.659h5.265c0,2.979,1.628,4.537,4.78,4.537,2.6,0,4.261-1.213,4.261-3.152,0-2.182-1.594-2.6-5.092-3.5-4.641-1.178-8.867-2.285-8.867-7.688,0-4.331,3.706-7.309,9.11-7.309C804.078,887.433,807.265,890.238,807.265,895.537Z" transform="translate(-31.116)"/>
                <path d="M834.533,892.661v5.576h11.154v4.33H834.533v6.028h12.261v4.365H829.441V888.3h17.042v4.365Z" transform="translate(-45.812 -0.309)"/>
                <path d="M892.272,912.959V900.7q0-4.624.208-9.248h-.069c-.8,3.013-1.7,6.1-2.632,9.076l-3.914,12.435h-5.82l-3.913-12.435c-.935-2.979-1.836-6.062-2.633-9.076h-.069q.209,4.625.208,9.248v12.263h-4.884V888.3h8.174l3.568,11.327c.935,2.979,1.766,6.062,2.425,9.075h.069c.659-3.013,1.49-6.1,2.425-9.075l3.568-11.327h8.174v24.663Z" transform="translate(-59.909 -0.309)"/>
                <path d="M934.806,888.3c6.131,0,8.971,2.563,8.971,6.339,0,2.84-1.42,4.675-4.295,5.749v.07c3.36.727,4.884,2.564,4.884,5.749,0,4.122-3.256,6.755-8.8,6.755h-9.63V888.3Zm-3.776,10.149h3.6c2.979,0,4.26-.935,4.26-3.048,0-2.044-1.212-2.944-4.018-2.944H931.03Zm0,10.321h3.776c3.152,0,4.4-1,4.4-3.081,0-2.217-1.282-3.223-4.537-3.223H931.03Z" transform="translate(-80.414 -0.309)"/>
                <path d="M982.9,908.594v4.365h-16.73V888.3h5.092v20.3Z" transform="translate(-94.839 -0.309)"/>
                <path d="M1006.436,906.931l-1.939,6.027h-5.335l8.555-24.663h6.858l8.555,24.663H1017.8l-1.906-6.027Zm7.551-5.992c-1-3.187-1.974-6.443-2.806-9.63h-.069c-.831,3.187-1.766,6.443-2.77,9.63l-.52,1.627h6.685Z" transform="translate(-106.67 -0.309)"/>
                <path d="M1096.27,892.661v5.576h11.154v4.33H1096.27v6.028h12.262v4.365h-17.353V888.3h17.042v4.365Z" transform="translate(-139.664 -0.309)"/>
                <g transform="translate(977.84 887.754)">
                  <path d="M1140.6,898.253l-.924-3.949c-.336-1.415-.63-2.843-.869-4.271h-.028c-.238,1.428-.532,2.856-.869,4.271l-.924,3.949h-2.675l-2.087-9.97h2.017l.714,3.627c.28,1.428.532,2.9.728,4.314h.028c.251-1.415.56-2.871.91-4.314l.868-3.627h2.6l.868,3.627c.35,1.443.659,2.9.91,4.314h.028c.2-1.415.448-2.885.728-4.314l.715-3.627h2.017l-2.087,9.97Z" transform="translate(-1132.221 -888.059)"/>
                  <path d="M1164.486,893.143c0,3.235-1.792,5.209-4.719,5.209s-4.719-1.974-4.719-5.209,1.792-5.209,4.719-5.209S1164.486,889.908,1164.486,893.143Zm-7.31,0c0,2.1.981,3.389,2.591,3.389s2.591-1.288,2.591-3.389-.98-3.389-2.591-3.389S1157.176,891.042,1157.176,893.143Z" transform="translate(-1140.406 -887.934)"/>
                  <path d="M1176.976,888.283c2.507,0,3.641,1.036,3.641,2.815a2.409,2.409,0,0,1-1.82,2.437v.028c.7.251,1.036.672,1.5,1.932l.995,2.759h-2.241l-.8-2.367c-.364-1.064-.77-1.442-1.75-1.442h-1.134v3.809h-2.059v-9.97Zm-1.61,4.523h1.456c1.246,0,1.722-.56,1.722-1.471,0-.966-.658-1.386-1.834-1.386h-1.344Z" transform="translate(-1146.953 -888.059)"/>
                  <path d="M1196.5,896.489v1.765h-6.764v-9.97h2.058v8.206Z" transform="translate(-1152.846 -888.059)"/>
                  <path d="M1207.417,888.283c3.389,0,5.294,1.792,5.294,4.985s-1.9,4.985-5.294,4.985H1204.1v-9.97Zm-1.274,8.206h1.246c2.142,0,3.192-1.064,3.192-3.221s-1.05-3.221-3.192-3.221h-1.246Z" transform="translate(-1157.994 -888.059)"/>
                </g>
                <path d="M1080,911.65" transform="translate(-135.656 -8.684)"/>
                <path d="M1066.49,895.681h-5.452c-.46-2.407-2.089-3.752-4.815-3.752-3.965,0-6.478,3.222-6.478,8.5,0,5.416,2.478,8.709,7.788,8.709a12.655,12.655,0,0,0,3.823-.566v-5.31H1055.8v-4.106h10.691v12.072a23.994,23.994,0,0,1-9.594,1.911c-8.036,0-12.391-4.814-12.391-12.709,0-8.071,4.461-12.992,11.718-12.992C1062.277,887.433,1065.994,890.654,1066.49,895.681Z" transform="translate(-122.929)"/>
              </g>
            </svg>
          </div>
        </header>
        <nav className="nav">
          <ul>
            <li>
              <button onClick={() => { setContent(about); setPanelOpen(true)}}>About</button>
            </li>
            <li>
              <button onClick={() => { setContent(studio); setPanelOpen(true)}}>Studio</button>
            </li>
            <li>
              <a href="https://views.assemblageworld.com" title="Views" target="_blank" rel="noreferrer">Magazine</a>
            </li>
            <li>
              <button onClick={() => { setContent(contact); setPanelOpen(true)}}>Contact</button>
            </li>
          </ul>
        </nav>
        <section className={`main ${panelOpen ? 'is-under' : ''}`}>
          <span className="location">{hours}<span className="blink">:</span>{mins} – Los Angeles, CA</span>
          <h1 className="reveal-text">Inspiring the global citizen to make courageous decisions that benefit one’s self, one’s neighbor, and one’s planet.</h1>
          <div className="subscribe-form">
            <h2>Register for launch updates</h2>
            <form action="https://assemblageworld.us1.list-manage.com/subscribe/post?u=39a5ea5fdebb23b8c8445f779&amp;id=127dffddfb" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
              <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="Email" required />
              <div id="mce-responses" className="clear">
                <div className="response" id="mce-error-response" style={{display: 'none'}}></div>
                <div className="response" id="mce-success-response" style={{display: 'none'}}></div>
              </div> 
              <div style={{position: 'absolute', left: -5000 + 'px'}} aria-hidden="true"><input type="text" name="b_39a5ea5fdebb23b8c8445f779_127dffddfb" tabIndex="-1" /></div>
              <div className="clear"><button type="submit" name="subscribe" id="mc-embedded-subscribe" className="button">Submit</button></div>
            </form>
          </div>
        </section>
      </div>
      <Panel
        close={closePanel}
        state={panelOpen}
        type={content} />
    </>
  )
}

export default App
